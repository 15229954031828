.social {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;

  a {
    margin: 30px;
    font-family: $font-family-secondary;
  }
}
