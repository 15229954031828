section {
  min-height: 100vh;
  padding: 70px 200px 120px 45px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: flex-start;

  @include media-breakpoint-down(md) {
    min-height: auto;
    max-height: none;
    padding: 40px 30px;
  }

  @include media-breakpoint-up(xl) {
    min-height: 50vh;
    max-height: 1000px;
  }
}
