@import "https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&family=Roboto+Mono:wght@300;400;500;600;700&display=swap";
body {
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #232526 10%, black);
  color: #ffffff;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 16px;
}
body * {
  box-sizing: border-box;
}
html, body {
  scroll-behavior: smooth;
}
section {
  min-height: 100vh;
  padding: 70px 200px 120px 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media (max-width: 991.98px) {
  section {
    min-height: auto;
    max-height: none;
    padding: 40px 30px;
  }
}
@media (min-width: 1600px) {
  section {
    min-height: 50vh;
    max-height: 1000px;
  }
}
nav {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 991.98px) {
  nav {
    display: none;
  }
}
nav ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}
nav li {
  margin: 15px 0;
  text-align: right;
}
nav li a {
  padding: 15px 45px 15px 30px;
  position: relative;
  transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  border-bottom: none !important;
  font-family: "Roboto Mono", sans-serif;
  font-size: 1.2em;
}
nav li a.active {
  font-weight: 700;
  color: #ff99df;
  background-color: rgba(255, 255, 255, 0.1);
}
nav li a:hover {
  opacity: 0.5;
}
nav .button {
  justify-content: flex-start;
  margin-left: auto;
  padding: 0 45px 0 30px;
  width: 100px;
  cursor: pointer;
  font-family: "Roboto Mono", sans-serif;
  font-size: 1.2em;
  transition: width 0.2s ease-in-out;
}
nav .button:hover {
  width: 120px;
}
a {
  text-decoration: none;
  color: #ffffff;
  border-bottom: 3px solid #ff99df;
}
a:hover {
  border-bottom-style: dashed;
}
h1, h2 {
  font-family: "Roboto Mono", sans-serif;
}
h1 {
  font-size: 3rem;
  margin-bottom: 30px;
  font-weight: 700;
}
h1:after {
  content: ".";
  color: #ff99df;
}
h2 {
  font-weight: 300;
  font-size: 1.5rem;
  margin-bottom: 30px;
  margin-top: 50px;
}
h2:before {
  content: ".";
  font-size: 3rem;
  font-weight: 700;
  color: #ff99df;
  margin-right: 10px;
}
footer {
  text-align: center;
  padding: 30px;
  opacity: 0.5;
}
p {
  font-size: 1.2em;
  line-height: 1.6;
}
.project {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 calc(30px / 2) 30px;
  position: relative;
  border: none !important;
  background: #232526;
  overflow: hidden;
}
.project:hover .project__img {
  filter: none;
}
.project:hover .project__desc {
  height: 50px;
  font-size: 0.8rem;
}
.project__img {
  background-size: cover !important;
  background-position: 0 0 !important;
  width: 100%;
  height: 100%;
  filter: grayscale(1);
}
.project__desc {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  font-size: 1.5rem;
  transition: height 0.2s ease-in-out, font-size 0.2s ease-in-out;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background: rgba(35, 37, 38, 0.5);
  backdrop-filter: blur(5px);
  font-weight: 700;
  width: 100%;
}
.project-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-1 * 30px / 2);
  margin-left: calc(-1 * 30px / 2);
}
.social {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
}
.social a {
  margin: 30px;
  font-family: "Roboto Mono", sans-serif;
}
.home {
  font-size: 150%;
}
.home p {
  line-height: 2;
}
.home .arrow {
  border-bottom: none;
  transition: transform 0.2s ease-in-out;
}
.home .arrow span {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-top: 3px solid #ff99df;
  border-right: 3px solid #ff99df;
  transform: rotate(45deg);
  margin-bottom: -3px;
  animation: arrowBounceX 1.5s ease-in-out forwards;
  animation-iteration-count: infinite;
}
.home .arrow:hover {
  margin-bottom: 0;
  margin-left: 10px;
}
.home .arrow:hover span {
  transform: rotate(135deg);
  animation-name: arrowBounceY;
}
@keyframes arrowBounceX {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 10px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes arrowBounceY {
  0% {
    margin-bottom: 5px;
  }
  50% {
    margin-bottom: -5px;
  }
  100% {
    margin-bottom: 5px;
  }
}
.button, button, [role=button] {
  background: #ff99df;
  color: #232526;
  line-height: 50px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  border: none !important;
  transition: background-color 0.2s ease-in-out;
  margin-top: 40px;
}
.button:hover, button:hover, [role=button]:hover {
  background-color: #ff66cf;
}
.button:focus, button:focus, [role=button]:focus {
  outline: none;
}
/*# sourceMappingURL=index.2df37571.css.map */
