.project {
  $gap: 30px;

  display: block;
  width: 200px;
  height: 200px;
  margin: 0 calc(#{$gap} / 2) $gap;
  position: relative;
  border: none !important;
  background: $dark;
  overflow: hidden;

  &:hover {
    .project__img {
      filter: none;
    }

    .project__desc {
      height: 50px;
      font-size: 0.8rem;
    }
  }

  &__img {
    background-size: cover !important;
    background-position: 0 0 !important;

    width: 100%;
    height: 100%;

    filter: grayscale(1);
  }

  &__desc {
    position: absolute;
    bottom: 0;
    left: 0;

    height: 100%;
    font-size: 1.5rem;
    transition: height 0.2s ease-in-out, font-size 0.2s ease-in-out;

    display: flex;
    align-items: center;

    padding: 0 15px;
    background: rgba($dark, 50%);
    backdrop-filter: blur(5px);
    font-weight: 700;
    width: 100%;
  }

  &-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    margin-right: calc(-1 * #{$gap} / 2);
    margin-left: calc(-1 * #{$gap} / 2);
  }
}
