body {
  margin: 0;
  padding: 0;

  background: linear-gradient(135deg, $dark 10%, black);
  color: $white;

  font-family: $font-family-base;
  font-weight: 300;
  font-size: 16px;

  * {
    box-sizing: border-box;
  }
}

html, body {
  scroll-behavior: smooth;
}
