.home {
  font-size: 150%;

  p {
    line-height: 2;
  }

  .arrow {
    border-bottom: none;

    transition: transform 0.2s ease-in-out;

    span {
      width: 25px;
      height: 25px;
      display: inline-block;
      border-top: 3px solid $pink;
      border-right: 3px solid $pink;
      transform: rotate(45deg);
      margin-bottom: -3px;

      animation: arrowBounceX 1.5s ease-in-out forwards;
      animation-iteration-count: infinite;
    }

    &:hover {
      margin-bottom: 0;
      margin-left: 10px;

      span {
        transform: rotate(135deg);
        animation-name: arrowBounceY;
      }
    }
  }
}

@keyframes arrowBounceX {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 10px;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes arrowBounceY {
  0% {
    margin-bottom: 5px;
  }
  50% {
    margin-bottom: -5px;
  }

  100% {
    margin-bottom: 5px;
  }
}
