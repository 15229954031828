h1, h2 {
  font-family: $font-family-secondary;

}

h1 {
  font-size: 3rem;
  margin-bottom: 30px;
  font-weight: 700;

  &:after {
    content: '.';
    color: $pink;
  }
}

h2 {
  font-weight: 300;
  font-size: 1.5rem;
  margin-bottom: 30px;
  margin-top: 50px;

  &:before {
    content: '.';
    font-size: 3rem;
    font-weight: 700;
    color: $pink;
    margin-right: 10px;
  }
}
